import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { Button, ThemeContext } from '@forma/forma-ui-kit';
import Link from '@/components/Link';
import Logo from '@/components/Logo';
import LangSwitch from '@/components/LangSwitch';
import SideMenu from '@/components/SideMenu';
import HeaderSelect from '@/components/blocksMiniBuisness/HeaderSelect';
import { analytics } from '@/helpers/analytics';
import { MainContext } from '@/context/MainContext';
import Submenu from './Submenu';

import styles from './header.module.css';

const lkUrl = process.env.LK_URL;

const Header = () => {
  const { t, i18n } = useTranslation();
  const { pathname } = useRouter();
  const { viewport } = useContext(ThemeContext);
  const { main } = useContext(MainContext);
  const isPhone = ['phone', 'tabletS'].includes(viewport);

  const [openSubmenu, setOpenSubmenu] = useState(false);
  const [isMouseInside, setIsMouseInside] = useState(false);

  const header = useRef(null);
  const submenu = useRef(null);
  const closeTimeout = useRef(null);

  const handleOnMouseEnter = () => {
    clearTimeout(closeTimeout.current);
    setOpenSubmenu(true);
    setIsMouseInside(true);
  };

  const handleOnMouseLeave = () => {
    closeTimeout.current = setTimeout(() => {
      setIsMouseInside(false);
    }, 500);
  };

  useEffect(() => {
    if (!submenu.current && !header.current) return;

    const submenuCur = submenu.current;
    const headerCur = header.current;

    const handleMouseMove = () => {
      if (!isMouseInside) setOpenSubmenu(false);
      if (isMouseInside) setOpenSubmenu(true);
    };

    const handleMouseLeave = () => {
      setOpenSubmenu(false);
    };

    submenuCur?.addEventListener('mouseleave', handleMouseLeave);
    headerCur?.addEventListener('mousemove', handleMouseMove);

    return () => {
      submenuCur?.removeEventListener('mouseleave', handleMouseLeave);
      headerCur?.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isMouseInside, openSubmenu]);

  return (
    <div className={classNames(styles.root, styles.sticky)}>
      <header className={styles.header} ref={header}>
        <div className="container">
          <Logo
            className={styles.logo}
            showText={viewport !== 'phone'}
            iconClassName={styles.logoIcon}
            textClassName={styles.logoText}
          />

          {isPhone ? (
            <div className={styles.navMobile}>
              <HeaderSelect />
            </div>
          ) : (
            <nav className={styles.links}>
              {/* <Button
                as={Link}
                href="/"
                className={classNames(
                  styles.link,
                  (pathname === '/' || pathname === '/[locale]') && styles.active
                )}
                viewStyle="text"
                // title={t('links.product')}
              >
                {t('product')}
              </Button> */}
              <Button
                as={Link}
                href="/tariffs"
                className={classNames(
                  styles.link,
                  (pathname === '/tariffs' || pathname === '/[locale]/tariffs') &&
                    styles.active
                )}
                viewStyle="text"
                // title={t('links.tariffs')}
              >
                {t('tariffs')}
              </Button>
              <Button
                as={Link}
                href="/request"
                className={classNames(styles.link)}
                viewStyle="text"
                // title={t('links.request')}
              >
                {t('demo')}
              </Button>
              <Button
                as={Link}
                href="/templates"
                className={classNames(
                  styles.link,
                  (pathname === '/templates' ||
                    pathname === '/[locale]/templates') &&
                    styles.active
                )}
                viewStyle="text"
                // title={t('links.templates')}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              >
                {t('ready_templates')}
              </Button>
            </nav>
          )}

          <div className={styles.buttons}>
            {isPhone ? (
              <SideMenu items={main?.menu} />
            ) : (
              <>
                <Button
                  as={Link}
                  href={`${lkUrl}/register?lng=${i18n.language}`}
                  className={styles.button}
                  viewStyle="primary"
                  onClick={analytics.clickRegisterHeader}
                >
                  {t('registration')}
                </Button>
                <Button
                  as={Link}
                  href={`${lkUrl}/login?lng=${i18n.language}`}
                  className={styles.button}
                  viewStyle="tertiary"
                  onClick={analytics.clickSignInHeader}
                >
                  {t('login')}
                </Button>
                {/* <Button
                  as={Link}
                  href="/request"
                  className={styles.button}
                  viewStyle="primary"
                >
                  {t('request_demo')}
                </Button> */}
                <LangSwitch className={styles.lang} />
              </>
            )}
          </div>
        </div>
      </header>
      {!isPhone && (
        <Submenu
          items={main?.submenu}
          openSubmenu={openSubmenu}
          ref={submenu}
        />
      )}
    </div>
  );
};

export default Header;
