'use client';

import { useContext, useState } from 'react';
import Slider from 'rc-slider';
import { useTranslation, Trans } from 'next-i18next';
import { Modal, Button, ThemeContext } from '@forma/forma-ui-kit';
import Link from '@/components/Link';
import ClientWrap from '@/components/ClientWrap';
import formatPrice from '@/helpers/formatPrice';
import { analytics } from '@/helpers/analytics';
import YearSelect from './YearSelect';
import EconomyTable from './EconomyTable';

import styles from './economy-calc.module.css';

const MISTAKES = 30;
const MISTAKES_FORMA = 0;

const lkUrl = process.env.LK_URL;

const EconomyCalc = () => {
  const { t, i18n } = useTranslation();
  const { currencies, lang } = useContext(ThemeContext);
  const currency = currencies[lang];

  const [ isModalOpen, setModalOpen ] = useState(false);
  const [ docs, setDocs ] = useState(21);
  const [ workers, setWorkers ] = useState(5);
  const [ salary, setSalary ] = useState(65000);
  const [ months, setMonths ] = useState(1);

  const workMonthPrice = (salary/21/8/100*145);

  const withoutFormaMistakes = docs*workers/100*MISTAKES;
  const withoutFormaHours = (docs*workers*15 + 30 * withoutFormaMistakes) / 60;
  const withoutFormaTotal = withoutFormaHours * workMonthPrice;

  const withFormaMistakes = docs*workers/100*MISTAKES_FORMA;
  const withFormaHours = (docs*workers*1 + 30 * withFormaMistakes) / 60;
  const withFormaTotal = withFormaHours * workMonthPrice;

  const diffMistakes = Math.ceil(((withoutFormaMistakes - withFormaMistakes)*months));
  const diffHours = Math.ceil(((withoutFormaHours - withFormaHours)*months));
  const diffTotal = Math.ceil(((withoutFormaTotal - withFormaTotal)*months));

  return (
    <div className={styles.root}>
      <h3 className={styles.title}>
        <Trans
          i18nKey="how_much_economy"
          components={{ accent: <span className="accent-text" /> }}
        />
      </h3>
      <div className={styles.content}>
        <div className={styles.left}>
          <div className={styles.leftTitle}>{t('how_much_contracts_per_month')}</div>
          <div className={styles.leftDescription}>{t('how_much_contracts_per_month_desscription')}</div>
          <div className={styles.leftRow}>
            <Button className={styles.leftButton} viewStyle="secondary" size="small">
              {t('count_documents', { count: docs })}
            </Button>
            <Slider
              marks={{ 10: { label: `${t('from')} 10` }, 300: { label: `${t('to')} 300` } }}
              min={10}
              max={300}
              value={docs}
              onChange={setDocs}
            />
          </div>

          <div className={styles.leftTitle}>{t('how_much_coworkers_fill_documents')}</div>
          <div className={styles.leftRow}>
            <Button className={styles.leftButton} viewStyle="secondary" size="small">
              {t('count_coworkers', { count: workers })}
            </Button>
            <Slider
              marks={{ 1: { label: `${t('from')} 1` }, 100: { label: `${t('to')} 100` } }}
              min={1}
              max={100}
              value={workers}
              onChange={setWorkers}
            />
          </div>

          <div className={styles.leftTitle}>{t('how_much_salary_coworkers')}</div>
          <div className={styles.leftRow}>
            <Button className={styles.leftButton} viewStyle="secondary" size="small">
              {formatPrice(salary, currency)} / {t('month')}
            </Button>
            <Slider
              marks={{ 20000: { label: `${t('from')} 20000` }, 200000: { label: `${t('to')} 200000` } }}
              min={20000}
              max={200000}
              value={salary}
              onChange={setSalary}
            />
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.rightTitle}>
            {t('with_forma_your_company')}
            <span className={styles.switch}>
              <YearSelect onChange={setMonths} />
            </span>
          </div>
          <div className={styles.rightRows}>
            <div className={styles.rightRow}>
              <Trans
                i18nKey="economy_text.1"
                values={{ hours: t('count_hours', { count: diffHours }) }}
                components={{ accent: <span className="accent-text" /> }}
              />
            </div>
            <div className={styles.rightRow}>
              <Trans
                i18nKey="economy_text.2"
                values={{ price: formatPrice(diffTotal, currency) }}
                components={{ accent: <span className="accent-text" /> }}
              />
            </div>
            <div className={styles.rightRow}>
              <Trans
                i18nKey="economy_text.3"
                values={{ errors: t('count_errors', { count: diffMistakes }) }}
                components={{ accent: <span className="accent-text" /> }}
              />
            </div>
          </div>
          <div className={styles.rightButtons}>
            <Button
              as={Link}
              className={styles.rightButton}
              href={`${lkUrl}/register?lng=${i18n.language}`}
              onClick={analytics.clickTryTrial}
            >{t('try_free_trial_days')}</Button>
            {/* {isPhone ? (
              <Button as={Link} className={styles.rightButton} href="/request">{t('request_demo')}</Button>
            ) : (
              <Button as={Link} className={styles.rightButton} href="/request">{t('request_online_demo')}</Button>
            )} */}
          </div>

          <div className={styles.rightFooter}>
            <span className={styles.link} onClick={() => setModalOpen(true)} tabIndex="0">{t('how_it_calc')}</span>
            <ClientWrap>
              <Modal
                open={isModalOpen}
                onClose={() => setModalOpen(false)}
              >
                <div className={styles.modalTitle}>{t('how_it_calc')}</div>
                <input type="hidden" autoFocus />
                <EconomyTable docs={docs} workers={workers} salary={salary} onClose={() => setModalOpen(false)} />
              </Modal>
            </ClientWrap>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EconomyCalc;
