import { useContext } from 'react';
import { useRouter } from 'next/router';
import { Select } from '@forma/forma-ui-kit';
import ClientWrap from '@/components/ClientWrap';
import { MainContext } from '@/context/MainContext';

import styles from './header-select.module.css';

const HeaderSelect = () => {
  const router = useRouter();
  const { main } = useContext(MainContext);

  const active = main?.homepageMenu.find(({ href }) => {
    const checkPath = href === '/' ? '/[locale]' : '/[locale]' + href;
    const isActive = router.pathname === checkPath;
    return isActive;
  });

  return (
    <ClientWrap>
      <Select
        id="header-select"
        name="header-select"
        viewStyle="secondary"
        className={styles.select}
        value={active?.id}
        onChange={e => {
          const option = main?.homepageMenu.find(({ id }) => id === e.target.value);
          if (option) {
            router.push(router.query.locale ? `/${router.query.locale}${option.href}` : option.href);
          }
        }}
        options={main?.homepageMenu.map(({ id, name }) => ({
          label: name,
          value: id,
        })) ?? []}
        meta={{
          dropdownClassName: styles.dropdown,
        }}
      />
    </ClientWrap>
  );
};

export default HeaderSelect;
