/* eslint-disable no-undef */

const vkId = process.env.VK_COUNTER_ID;
const ymId = process.env.YM_COUNTER_ID;

export const analytics = {
  sendRequestFullForm: () => {
    window._tmr?.push({ type: 'reachGoal', id: vkId, goal: 'Sign_up_for_a_demo' });
    // ym(ymId, 'reachGoal', 'make_demo');
  },
  sendRequestForm: () => {
    window._tmr?.push({ type: 'reachGoal', id: vkId, goal: 'Sign_up_for_a_demo' });
    ym(ymId, 'reachGoal', 'send');
  },
  viewTariffsPage: () => window._tmr?.push({ type: 'reachGoal', id: vkId, goal: 'search_tarif' }),

  sendSEORequestForm: () => {
    window._tmr?.push({ type: 'reachGoal', id: vkId, goal: 'Sign_up_for_a_demo' });
    ym(ymId, 'reachGoal', 'SEO_Enter_send_phone');
  },
  clickDownloadDocument: () => ym(ymId, 'reachGoal', 'seo.try_to_make_document'),
  clickSEOTryTrial: () => ym(ymId, 'reachGoal', 'SEO_try_7_day_free'),
  clickTryForma: () => ym(ymId, 'reachGoal', 'try_forma'),
  clickFillTemplate: () => ym(ymId, 'reachGoal', 'Fill_in_temple'),
  clickRequestDemo: () => ym(ymId, 'reachGoal', 'SEO_demo'),
  clickSendEmailDownload: () => ym(ymId, 'reachGoal', 'SEO_Enter_send_email'),
  clickShowExample: () => ym(ymId, 'reachGoal', 'seo_see_temple'),

  clickRegisterHeader: () => ym(ymId, 'reachGoal', 'Registration_header'),
  clickSignInHeader: () => ym(ymId, 'reachGoal', 'Enter_header'),

  clickTryTrial: () => ym(ymId, 'reachGoal', 'Try_it_for_7_days_for_free'),
  clickTryTariff: () => ym(ymId, 'reachGoal', 'Landing_try_tarif'),
};