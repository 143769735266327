import { useContext, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import Link from '@/components/Link';
import { Button, ThemeContext } from '@forma/forma-ui-kit';
import { LangSwitchLinks } from '@/components/LangSwitch';
import Logo from '@/components/Logo';
import ClientWrap from '@/components/ClientWrap';
import { analytics } from '@/helpers/analytics';

import styles from './side-menu.module.css';

const lkUrl = process.env.LK_URL;

const SideMenu = ({ items }) => {
  const { t, i18n } = useTranslation();
  const { pathname } = useRouter();
  const { viewport } = useContext(ThemeContext);
  const [ isOpen, setIsOpen ] = useState(false);

  return (
    <>
      <ReactSVG src="/icons/hamburger.svg" className={styles.menuButton} onClick={() => setIsOpen(true)} />
      <div className={classNames(styles.overlay, isOpen && styles.open)} onClick={() => setIsOpen(false)} />
      <div className={classNames(styles.menu, isOpen && styles.open)}>
        <div className={styles.head}>
          {viewport === 'phone' ? (
            <Logo id="logo_menu" iconClassName={styles.logoIcon} showText />
          ) : (
            t('menu')
          )}
          <ReactSVG src="/icons/close.svg" className={styles.closeButton} onClick={() => setIsOpen(false)} />
        </div>
        <div className={styles.content}>
          <div className={styles.items}>
            {/* {items?.map(({ id, items, title, href }) => (
              <div className={styles.item} key={id}>
                {href ? (
                  <Link className={styles.title} href={href} onClick={() => setIsOpen(false)}>{title}</Link>
                ) : (
                  <div className={styles.title}>{title}</div>
                )}
                {items?.map(({ id, name, href, title }) => (
                  <div key={id}>
                    <Link
                      href={href}
                      className={classNames(styles.link, (pathname === '/' || pathname === '/[locale]') && styles.active)}
                      onClick={() => setIsOpen(false)}
                      title={title}
                    >
                      {name}
                    </Link>
                  </div>
                ))}
              </div>
            ))} */}
            <Button
              as={Link}
              href="/tariffs"
              className={classNames(styles.link, (pathname === '/tariffs' || pathname === '/[locale]/tariffs') && styles.active)}
              viewStyle="text"
              fullWidth
              // title={t('links.tariffs')}
            >
              {t('tariffs')}
            </Button>
            <Button
              as={Link}
              href="/request"
              className={classNames(styles.link, (pathname === '/request' || pathname === '/[locale]/request') && styles.active)}
              viewStyle="text"
              fullWidth
              // title={t('links.request')}
            >
              {t('demo')}
            </Button>
            <Button
              as={Link}
              href="/templates"
              className={classNames(styles.link, (pathname === '/templates' || pathname === '/[locale]/templates') && styles.active)}
              viewStyle="text"
              fullWidth
              // title={t('links.templates')}
            >
              {t('ready_templates')}
            </Button>
            <Button
              as={Link}
              href={`${lkUrl}/login?lng=${i18n.language}`}
              className={styles.link}
              iconClassName={styles.linkIcon}
              icon={<ReactSVG src="/icons/sign_in_shrink.svg" wrapper="span" />}
              onClick={analytics.clickSignInHeader}
              viewStyle="text"
              fullWidth
            >
              {t('login')}
            </Button>
          </div>
          <div className={styles.buttons}>
            <ClientWrap>
              <LangSwitchLinks onChange={() => setIsOpen(false)} />
            </ClientWrap>
            <Button
              as={Link}
              className={styles.button}
              viewStyle="primary"
              href={`${lkUrl}/register?lng=${i18n.language}`}
              onClick={analytics.clickRegisterHeader}
              fullWidth
            >
              {t('registration')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideMenu;
