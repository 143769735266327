import { useState } from 'react';
import { useCookie } from 'next-cookie';
import { useTranslation } from 'next-i18next';
import RequestFormSimple from '@/components/blocks/RequestFormSimple';
import { requests } from '@/helpers/api';
import { analytics } from '@/helpers/analytics';

import styles from './request-call.module.css';

const RequestCall = () => {
  const { t, i18n } = useTranslation();
  const cookie = useCookie();
  const [formStatus, setFormStatus] = useState(false);
  const [saveResult, setSaveResult] = useState(null);

  const handleSubmit = async (values, slotId) => {
    // if (!values.date_time?.utc?.utc || !values.date_time?.slot) {
    //   setFormStatus('error');
    //   return null;
    // }

    // const data = {
    //   timeslotId: values.date_time.slot,
    //   clientEmail: values.email,
    //   clientPhone: values.phone,
    //   companyName: values.company_name,
    //   clientFullName: values.name,
    //   gmt: values.date_time.utc.utc,
    //   chanel: values.social,
    //   roistatVisit: cookie.get('roistat_visit'),
    // };
    const data = {
      timeslotId: slotId,
      clientPhone: values.phone,
      clientEmail: 'demo@client.com',
      companyName: 'Не указано',
      clientFullName: 'Не указано',
      gmt: '0',
      chanel: t('uikit:call'),
      roistatVisit: cookie.get('roistat_visit')
    };

    const result = await requests.setTimeslot(data, i18n.language);
    if (result) {
      setFormStatus('success');
      // setSaveResult({
      //   date: values.date_time.date,
      //   utc: values.date_time.utc.utc,
      // });
    } else setFormStatus('error');
    // window.scrollBy({ top: -350, behavior: 'smooth' });

    analytics.sendRequestForm();
  };

  return (
    <div className={styles.root}>
      <RequestFormSimple
        className={styles.form}
        title="Оставьте номер и мы свяжемся с вами "
        subtitle=""
        onSubmit={handleSubmit}
        isSendSuccess={formStatus === 'success'}
        isSendError={formStatus === 'error'}
        saveResult={saveResult}
      />
    </div>
  );
};

export default RequestCall;
