import { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'next-i18next';
import { useForm } from 'react-hook-form';
import {
  Button,
  phonemasks,
  PhoneInput,
} from '@forma/forma-ui-kit';
import classNames from 'classnames';
import Link from '@/components/Link';
import ControlField from '@/components/fields/ControlField';
import { queries } from '@/helpers/api';

import styles from './request-form-simple.module.css';

const RequestFormSimple = ({
  title,
  subtitle,
  onSubmit,
  isSendSuccess,
  isSendError,
  className
}) => {
  const { t, i18n } = useTranslation();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
  });
  const [phoneValidation, setPhoneValidation] = useState('');
  const [timeslots, setTimeslots] = useState(null);

  useEffect(() => {
    if (i18n && phonemasks) {
      const code = i18n.language === 'en' ? 'US' : i18n.language.toUpperCase();
      const mask = phonemasks.find(({ iso }) => iso === code);
      if (mask) setPhoneValidation(mask.code + mask.mask);
    }
  }, [i18n]);

  useEffect(() => {
    const getTimeslots = async () => {
      const res = await queries.getTimeslots();
      setTimeslots(res, i18n.language);
    };
    getTimeslots();
  }, [i18n.language]);

  const handleChangeMask = ({ mask, code }) => {
    setPhoneValidation(code + mask);
  };

  const onFormSubmit = (values) => {
    onSubmit(values, timeslots?.find(({ available }) => available).id);
  };

  return (
    <form className={classNames(styles.root, className)} onSubmit={handleSubmit(onFormSubmit)}>
      {isSendSuccess || isSendError ? (
        isSendSuccess ? (
          <div className={styles.result}>
            <div className={styles.resultTitle}>
              <Trans
                i18nKey="you_signed_up_for_demonstration"
                components={{ accent: <span className="accent-text" /> }}
              />
            </div>
            <div className={styles.resultSubtitle}>
              <Trans i18nKey="request_form_success" />
            </div>
            <div className={styles.buttons}>
              <Button
                as={Link}
                className={styles.button}
                viewStyle="primary"
                size="medium"
                href="/"
              >
                {t('go_to_homepage')}
              </Button>
            </div>
          </div>
        ) : (
          <div className={styles.result}>
            <div className={styles.title}>{t('contacts_form_send_error')}</div>
            <div className={styles.buttons}>
              <Button
                className={styles.button}
                viewStyle="primary"
                size="medium"
                onClick={() => window.location.reload()}
              >
                {t('try_again')}
              </Button>
            </div>
          </div>
        )
      ) : (
        <>
          <div className={styles.title} id="request_title">{title ?? t('leave_your_contacts')}</div>
          <div className={styles.subtitle}>{subtitle ?? t('we_will_set')}</div>
          <div className={styles.fields}>
            <ControlField
              id="phone"
              component={PhoneInput}
              masks={phonemasks}
              defaultMaskCode={
                i18n.language === 'en' ? 'US' : i18n.language.toUpperCase()
              }
              onChangeMask={handleChangeMask}
              name="phone"
              placeholder={t('phone_number')}
              control={control}
              error={errors.phone}
              errorMessage={errors.phone?.message}
              {...register('phone', {
                required: t('please_fill_the_field'),
                minLength: {
                  value: phoneValidation.length,
                  message: t('errors.min_lenght_phone'),
                },
              })}
              autoComplete="off"
              containerClass={styles.inputContainer}
              className={styles.phoneInputInput}
            />
          </div>
          <div className={styles.buttons}>
            <Button
              type="submit"
              className={styles.button}
              viewStyle="primary"
              size="medium"
              disabled={!isValid}
            >
              {t('send')}
            </Button>
          </div>
          <div className={styles.notice}>
            <Trans
              i18nKey="user_agreement_form_notice"
              components={{
                privacy: <Link href="/privacy" />,
                license: <Link href="/license" />,
                button: t('send'),
              }}
            />
          </div>
        </>
      )}
    </form>
  );
};

export default RequestFormSimple;
