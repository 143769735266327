import { useTranslation } from 'next-i18next';
import { Button } from '@forma/forma-ui-kit';
import Link from '@/components/Link';
import { analytics } from '@/helpers/analytics';

import styles from './counts.module.css';

const lkUrl = process.env.LK_URL;

const Counts = ({ items }) => {
  const { t, i18n } = useTranslation();

  if (!items) return null;

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        {items.map(({ title, description }, index) => (
          <div className={styles.item} key={index}>
            <div className={styles.title}>{title}</div>
            <div className={styles.description}>{description}</div>
          </div>
        ))}
      </div>
      <div className={styles.buttons}>
        <Button
          as={Link}
          className={styles.button}
          viewStyle="primary"
          href={`${lkUrl}/register?lng=${i18n.language}`}
          onClick={analytics.clickTryTrial}
        >
          {t('try_free_trial_days')}
        </Button>
      </div>
    </div>
  );
};

export default Counts;
